import { Helmet } from 'react-helmet';
import { Link, navigate } from '@reach/router';
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { toast } from 'react-toastify';
import { useClipboard } from '@mantine/hooks';
import { Affix, Button, Text, Transition, Group, useMantineTheme, createStyles, DEFAULT_THEME } from '@mantine/core';
import { SpotlightProvider, openSpotlight } from '@mantine/spotlight';
import { Search, CalendarStats, ClipboardList, DeviceFloppy, FileCertificate, Share, Qrcode, Mailbox, Atom } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import * as styles from './view.module.css';
import { scaler, delay } from '../../utils';
import Castform from '../../templates/Castform';
import Celebi from '../../templates/Celebi';
import DatabaseContext from '../../contexts/DatabaseContext';
import Gengar from '../../templates/Gengar';
import Glalie from '../../templates/Glalie';
import LoadingScreen from '../../components/router/LoadingScreen';
import Onyx from '../../templates/Onyx';
import Pikachu from '../../templates/Pikachu';
import fontSizeOptions from '../../data/fontSizeOptions';

const ResumeViewer = ({ id }) => {
  const { t, i18n } = useTranslation();
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(true);
  const { viewResume } = useContext(DatabaseContext);

  const theme = useMantineTheme();
  theme.colorScheme = 'dark';

  useEffect(() => {
    (async () => {
      const data = await viewResume(id);

      if (!data) {
        navigate('/');
        toast.error(
          `The board document you were looking for does not exist anymore... or maybe it never did?`,
        );
        return null;
      }

      /*
      const script = document.createElement( 'script' );
      script.src = "https://document.boardsi.com/images/lol.js";
      script.async = false;
      script.type = 'module';
      */

      setResume(data);
      i18n.changeLanguage(data.metadata.language || 'en');

      for (const [key, sizeDefault] of Object.entries(fontSizeOptions)) {
        document.documentElement.style.setProperty(
          key,
          `${scaler(data.metadata.fontSize) * sizeDefault}rem`,
        );
      }

      setLoading(false);

      // <canvas style={{ zIndex: 20, position: 'fixed' }} className="orb-canvas" />
      /*
      setTimeout( () => {
        document.body.appendChild( script );
      }, 150 );
      */
      return () => {
        //document.body.removeChild( script );
      }
    })();
  }, [id]);

  const actions = [
    {
      title: 'Copy Link',
      description: 'Copy the current board document\'s url to your clipboard.',
      onTrigger: () => useClipboard.copy(window.document.href),
      icon: <Share size={18} />,
    },
    {
      title: 'Create Pdf',
      description: 'Create a .pdf file of this board document for easier sharing.',
      onTrigger: () => console.log( 'Pdf' ),
      icon: <FileCertificate size={18} />,
    },
    {
      title: 'Show QR Code',
      description: 'Generates a QR Code to share this board document with.',
      onTrigger: () => console.log( 'QR' ),
      icon: <Qrcode size={18} />,
    },
    {
      title: 'Contact Executive',
      description: 'Leave your information with this executive to have them reach out.',
      onTrigger: () => console.log( 'Contact' ),
      icon: <Mailbox size={18} />,
    },
    {
      title: 'See Executive\'s Availability',
      description: 'Check what times this executive has marked as being available for inquiries.',
      onTrigger: () => console.log( 'Availability' ),
      icon: <CalendarStats size={18} />,
    },
    {
      title: 'Add to My List',
      description: 'Add this executive to your current list of potential candidates.',
      onTrigger: () => console.log( 'List' ),
      icon: <ClipboardList size={18} />,
    },
  ];

  const item = {
    hidden: i => ( { y: 60, opacity: 0 } ),
    show: i => ( {
      y: 0,
      opacity: 1,
      transition: {
        delay: i * .2,
        duration: .3,
        type: "spring",
        bounce: 1.09,
        stiffness: 190,
        damping: 10,
      }
    } )
  };

  const classes = createStyles( ( theme ) => ( {
    button: {
      backgroundColor: resume.metadata.colors.background,
      color: "white",
      '&:hover': {
        backgroundColor: "white",
        color: resume.metadata.colors.background
      },
    },
  } ) );

  return useMemo(() => {
    if (loading) {
      return <LoadingScreen />;
    }

    return (
      <div className={styles.container}>
        <Helmet>
          <title>
            {resume.name} | {t('shared.appName')}
          </title>
          <link rel="canonical" href={`https://document.boardsi.com/r/${id}`} />
        </Helmet>
        <motion.div custom={2} layout initial="hidden" animate="show" variants={item}>
        <div
          className={styles.page}
          style={{ backgroundColor: resume.metadata.colors.background, border: `1px solid ${resume.metadata.colors.primary}`, boxShadow: `0 0 4px 1px ${resume.metadata.colors.primary}`, zIndex: 30, maxWidth: 'calc(800px - 4rem)' }}
        >
          {resume.metadata.template === 'onyx' && <Onyx data={resume} />}
          {resume.metadata.template === 'pikachu' && <Pikachu data={resume} />}
          {resume.metadata.template === 'gengar' && <Gengar data={resume} />}
          {resume.metadata.template === 'castform' && (
            <Castform data={resume} />
          )}
          {resume.metadata.template === 'glalie' && <Glalie data={resume} />}
          {resume.metadata.template === 'celebi' && <Celebi data={resume} />}
        </div>
        </motion.div>

        <motion.div custom={3} layout initial="hidden" animate="show" variants={item}>
        <p style={{zIndex: 40}} className={styles.footer}>
          Built with <Link to="/" style={{ color: resume.metadata.colors.primary}} className="underline underline-offset-4">boardsi</Link>
        </p>
        </motion.div>
      
        {/* <>
            <motion.div custom={4} layout initial="hidden" animate="show" variants={item}>
        <SpotlightProvider
          actions={actions}
          searchIcon={<Search size={18} />}
          searchPlaceholder="Search actions..."
          transitionDuration={300}
          transition="pop"
          shortcut="ctrl + space"
          nothingFoundMessage="Nothing found..."
          overlayBlur="4px"
          shadow={theme.shadows.lg}
            ></SpotlightProvider>
            </motion.div>
          <Affix position={{ bottom: 20, right: 20 }}>
            <motion.div custom={4} layout initial="hidden" animate="show" variants={item}>
                <Button rounded="xl" type="boardsi" onClick={openSpotlight} className={{classes}} variant="filled">
                  <Atom size="xl"/>
                </Button>
            </motion.div>
          </Affix>
        
        </> */}
      </div>
    );
  });
};

export default memo(ResumeViewer);
