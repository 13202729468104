// extracted by mini-css-extract-plugin
export var blue0 = "view-module--blue-0--rJxS8";
export var blue1 = "view-module--blue-1--mH6h1";
export var blue2 = "view-module--blue-2--tTdom";
export var blue3 = "view-module--blue-3--dkAWi";
export var blue4 = "view-module--blue-4--pqbZS";
export var blue5 = "view-module--blue-5--ic+3r";
export var canvasContainer = "view-module--canvasContainer--aJnhU";
export var container = "view-module--container--mXMwY";
export var footer = "view-module--footer--IlsDv";
export var green0 = "view-module--green-0--4InNx";
export var green1 = "view-module--green-1--RsbR0";
export var green2 = "view-module--green-2--6WjTb";
export var green3 = "view-module--green-3--5MWf1";
export var green4 = "view-module--green-4--+4vk5";
export var hexagon = "view-module--hexagon--b0al5";
export var orbCanvas = "view-module--orb-canvas--+A307";
export var page = "view-module--page--M8ejY";
export var pulseBlue = "view-module--pulse-blue--qplS0";
export var pulseGreen = "view-module--pulse-green--74djL";
export var pulseRed = "view-module--pulse-red---CS0G";
export var red0 = "view-module--red-0--hpg+Q";
export var red1 = "view-module--red-1--m-MX2";
export var red2 = "view-module--red-2--f+u-Z";
export var red3 = "view-module--red-3--jc3dQ";
export var red4 = "view-module--red-4--viaDO";
export var templateHandle = "view-module--templateHandle--UZSCU";